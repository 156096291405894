<template>
  <header>
    <!-- first header -->
    <div class="top-contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="right-con" v-if="name != null">
              <router-link
                to="/chat"
                tag="a"
                @click.native="showChatIcon = false"
              >
                <span class="icon">
                  <i class="far fa-envelope"></i>
                  <span v-if="showChatIcon && chatLength > 0">
                    {{ chatLength }}
                  </span>
                </span>
              </router-link>
              <h3 class="ar">أهلا بك {{ name }}</h3>
              <h3 class="en">welcome {{ name }}</h3>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="left-con">
              <ul class="list-unstyled">
                <li>
                  <a href=""><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a href=""><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href=""><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href=""><i class="fab fa-facebook-f"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- second header -->
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-6">
            <div class="logo">
              <router-link to="/" tag="a">
                <img src="../../assets/images/logo/logo.png" />
              </router-link>
            </div>
          </div>
          <div class="col-6 col-sm-6">
            <div class="login-div">
              <router-link
                to="/login"
                tag="a"
                class="btn btn-main"
                v-if="!loginStatus"
              >
                <span class="ar"> انشاء حساب / دخول </span>
                <span class="en"> Login / Register </span>
              </router-link>
              <button @click="logoutNow()" class="btn btn-main" v-else>
                <span class="ar"> تسجيل خروج </span>
                <span class="en"> Log Out </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      loginStatus: false,
      name: localStorage.getItem("mawjood-user"),
      chatLength: "",
      showChatIcon: true,
      baseURL: "http://mawjoud.sa/mogood/public/api",
    };
  },
  mounted() {
    var token = localStorage.getItem("token");
    if (!token) {
      this.loginStatus = false;
    } else {
      this.loginStatus = true;
      this.chatLength = localStorage.getItem("userMessages");
    }
  },
  methods: {
    logoutNow() {
      this.loginStatus = false;
      location.reload();
      localStorage.removeItem("token");
      localStorage.removeItem("mawjood-user");
    },
  },
  components: {},
};
</script>
